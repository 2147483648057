let Grid = {
    noResults: '<div class="tt-no-results"><p>No results</p></div>',
    basicTemplate: {
        empty: this.noResults,
    },
    init: () => {
        jQuery(document).scroll(function () {
            let $nav = jQuery(".navbar.fixed-top");
            $nav.toggleClass('scrolled', jQuery(this).scrollTop() > $nav.height());
        });
    },
    remoteEngine: function (remoteUrl, limit, rateLimit) {
        return new Bloodhound({
            remote: {
                url: remoteUrl,
                wildcard: '%QUERY',
                rateLimitWait: rateLimit
            },
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            filter: function (values) {
                return $.map(values, function (value) {
                    return {value: value};
                });
            },
            limit: limit
        });
    },
    searchByCity: function (city) {
        let geonamesEngine = Grid.remoteEngine("/auto_completions/cities.json?query=%QUERY", 10, 300);
        geonamesEngine.initialize();


        let geonamesTemplates = {
            empty: Grid.noResults,
            suggestion: Handlebars.compile('<p><strong>{{city}}</strong>, {{#if geonames_admin2}}{{geonames_admin2}}, {{/if}}{{#if geonames_admin1}}{{geonames_admin1}}, {{/if}} {{country_name}}</p>')
        };
        city.typeahead({
            minLength: 2
        }, {
            name: "city-filter",
            display: "value",
            source: geonamesEngine.ttAdapter(),
            templates: geonamesTemplates
        });

        city.on('typeahead:selected', function (event, geoname) {
            jQuery('#search_geonames_filter').val(geoname.id);
        });

        city.on('input', function () {
            jQuery('#search_geonames_filter').val('');
        });
    },
    searchByCountry: function (country) {
        let engine = Grid.remoteEngine("/auto_completions/countries.json?query=%QUERY", 10, 300);
        engine.initialize();

        country.typeahead({
            minLength: 2
        }, {
            name: "country-filter",
            display: "value",
            source: engine.ttAdapter(),
            templates: Grid.basicTemplate,
        });
    }
};

jQuery(document).ready(Grid.init)

window.submitDisambiguateForm = function () {
    jQuery('#ingrid').submit();
};

document.addEventListener("turbolinks:load", function () {
    // Search by city name
    let city = jQuery('#search_city_filter');
    if (city.length > 0) {
        Grid.searchByCity(city)
    }

    // Search by country name
    let country = jQuery('#search_country_filter');
    if (country.length > 0) {
        Grid.searchByCountry(country)
    }

    if ($('.owl-carousel').length > 0) {
        $('.owl-carousel').owlCarousel({
            nav: false,
            center: true,
            loop: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            responsiveClass: true,
            items: 4,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 5,
                }
            }
        });
    }
});
document.addEventListener("turbolinks:before-cache", function () {
    if ($('.owl-carousel').length > 0) {
        $('.owl-carousel').owlCarousel('destroy');
    }
});
